<template>
  <v-container align="start">
    <v-card>
      <v-card-title>Your Time Entries</v-card-title>
      <v-card-text>
        <v-select />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TimeTracker",

  beforeCreate() {
    this.$store.dispatch("getLaborCharges");
  },

  computed: {
    ...mapState({
      user: state => state.auth.currentUser,
      laborCharges: state => state.timeTracker.laborCharges
    })
  }
};
</script>

<style lang="scss"></style>
